/*
.bold-text-large {
  border: 1px solid red;
}
*/

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
